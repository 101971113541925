import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMatModule } from "./shared/modules/ngmat.module";
import { SnackBarModule } from './shared/snack-bar/snack-bar.module';
import { AccountsApiModule } from './shared/api/accounts/accounts-api.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { configInitializerFactory } from './shared/app-initializer.service';
import { DeploymentConfigService } from './shared/deployment-config.service';
import { PermissionModule } from './modules/permission/permission.module';
import { RootPageComponent } from './root/root-page/root-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    RootPageComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgMatModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SnackBarModule,
    AccountsApiModule,
    PermissionModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializerFactory,
      multi: true,
      deps: [DeploymentConfigService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
