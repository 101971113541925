import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/modules/permission/service/permission.service';
import { StoreService } from 'src/app/shared/store.service';

@Injectable({
  providedIn: 'root'
})
export class RootGuard  {
  constructor(
    private readonly store: StoreService,
    private readonly permission: PermissionService,
    private readonly router: Router,
  ) { }

  canActivate(): boolean {
    if (!this.store.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }

    if (this.permission.canViewAdmin()) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    if (this.permission.canViewClients()) {
      this.router.navigate(['/clients']);
      return false;
    }

    console.warn(`RootGuard found a user that was logged in with no valid roles; clearing login`);
    this.store.clearUser();
    this.router.navigate(['/login']);
    return false;
  }

}
