import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { StoreService } from "../../../store.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly storeService: StoreService,
    private readonly router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (!(error instanceof HttpErrorResponse && error.status === 401)) {
          return throwError(error);
        } else {
          this.clearAndNavigateToLogin();
          return throwError(error);
        }
      })
    );
  }

  private clearAndNavigateToLogin() {
    this.storeService.clearUser();
    this.router.navigateByUrl('login');
  }
}
