import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from '../interceptors/auth-token/auth-token.interceptor';
import { HttpErrorInterceptor } from '../interceptors/http-error/http-error.interceptor';
import { AccountApiService } from './account-api.service';
import { AuthenticationApiService } from './authentication-api.service';
import { UserApiService } from './user-api.service';
import { AuthenticationAdapter } from './adapters/authentication.adapter';
import { UserAdapter } from './adapters/user.adapter';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: OpenTracingInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    AccountApiService,
    AuthenticationApiService,
    UserApiService,
    AuthenticationAdapter,
    UserAdapter,
  ]
})
export class AccountsApiModule { }
