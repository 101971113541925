import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientApiService } from './client-api.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from '../interceptors/auth-token/auth-token.interceptor';
import { HttpErrorInterceptor } from '../interceptors/http-error/http-error.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    ClientApiService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: OpenTracingInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ]
})
export class ClientApiModule { }
