import { EventEmitter, Injectable } from "@angular/core";
import { TokenService } from "../../token.service";
import { StoreService } from "../../store.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { from, Observable, of } from "rxjs";
import { map, pluck, switchMap, tap } from "rxjs/operators";
import { UserStatus } from "./models/user-status.enum";
import { DeploymentConfigService } from '../../deployment-config.service';
import { AuthProvidersResponse, AuthType, AuthTypeResponse, Authentication } from './models/authentication';
import { User } from './models/user';
import { AccessTokenResponse, ACCESS_TOKEN_MEDIA_TYPE } from './models/tunnr-access-token';
import { AuthenticationAdapter } from './adapters/authentication.adapter';
import { AccountApiService } from "./account-api.service";
import { HttpHeader } from '../http-header';
import { OpenIDService } from '../../services/openid';

@Injectable({
  providedIn: null,
})
export class AuthenticationApiService {
  private apiUrl!: string;
  public userUpdated = new EventEmitter<boolean>(true);

  constructor(
    private readonly http: HttpClient,
    private readonly accountService: AccountApiService,
    private readonly storeService: StoreService,
    private readonly tokenService: TokenService,
    private readonly config: DeploymentConfigService,
    private readonly authenticationAdapter: AuthenticationAdapter,
    private readonly openIdUtils: OpenIDService,
  ) {
    this.config.configLoaded.subscribe(() => {
      this.apiUrl = this.config.get('api');
    });
  }

  public loginWithCredentials(username: string, password: string): Observable<Authentication> {
    const credentials = `${username}:${password}`;
    return this.login(credentials);
  }

  public loginWithOidc(): Observable<Authentication> {
    return this.getSupportedProviders().pipe(
      switchMap(res => {
        return this.getAuthToken(res[0]);
    }));
  }

  public isOIDC(): Promise<boolean> {
    return this.getAuthType().pipe(map(type =>{
      return type === AuthType.OIDC;
    })).toPromise();
  }

  public authenticateWithOpenId() {
    this.getSupportedProviders().subscribe(async res => {
      window.open(await this.openIdUtils.generateOpenIdUrl(res[0]), '_self');
    });
  }

  getSelf(): Observable<User> {
    const token = this.storeService.getAccessToken();
    if (token) {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const claims = this.tokenService.decodeJwtClaims(token);
      return this.accountService.getOwnAccount();
    }
    return of({
      userId: '',
      mail: '',
      givenName: '',
      surname: '',
      status: UserStatus.Disabled,
      admin: false,
      client: false
    });
  }

  public hasAuthCode = (): boolean => !!this.getAuthCode();

  public hasErrorCode = (): boolean => !!this.getErrorCode();

  public getErrorCode = (): string => new URLSearchParams(window.location.search).get('error_code') ?? '';

  public verifyState = (): boolean => this.getStateResponse() === this.openIdUtils.getState();

  private getStateResponse = (): string => new URLSearchParams(window.location.search).get('state') ?? '';

  private getAuthCode = (): string => new URLSearchParams(window.location.search).get('code') ?? '';

  private getSupportedProviders(): Observable<AuthProvidersResponse[]> {
    const headers = { [HttpHeader.Accept]: 'application/vnd.io.tunnr.api.identity.provider.v1+json' };
    return this.http.get<AuthProvidersResponse[]>(`${this.apiUrl}/auth/providers`, { headers });
  }

  private getAuthType(): Observable<AuthType> {
    const headers = { [HttpHeader.Accept]: 'application/vnd.io.tunnr.api.server.v1+json' };
    return this.http.get<AuthTypeResponse>(`${this.apiUrl}/server`, { headers }).pipe(pluck("auth_type"));
  }

  private getAuthToken(provider: AuthProvidersResponse): Observable<Authentication> {
    const tokenUrl = new URL(`${this.apiUrl}/auth/providers/${provider.id}/token`);

    const headers = { [HttpHeader.Accept]: 'application/vnd.io.tunnr.api.access.token.v1+json' };
    tokenUrl.searchParams.append('code', this.getAuthCode());
    tokenUrl.searchParams.append('redirect', `${window.origin}/login`);
    tokenUrl.searchParams.append('code_verifier', `${this.openIdUtils.getCodeVerifier()}`);

    return this.http.get<AccessTokenResponse>(tokenUrl.toString(), { headers })
      .pipe(
        switchMap(res => {
          return from(this.tokenService.isValid(res.access_token))
            .pipe(
              tap(isValid => {
                if (!isValid) {
                  throw new Error('invalid access token');
                }
              }),
              map(_ => {
                const tokenClaims = this.tokenService.decodeJwtClaims(res.access_token);
                return this.authenticationAdapter.fromJwtClaims(tokenClaims, res.access_token);
              })
            );
        }),
        tap(() => this.userUpdated.emit(true)),
      );
  }

  private login(credentials: string): Observable<Authentication> {
    const options = {
      headers: new HttpHeaders({
        [HttpHeader.ContentType]: ACCESS_TOKEN_MEDIA_TYPE,
        [HttpHeader.Authorization]: `Basic ${btoa(credentials)}`,
      }),
    };

    return this.http.get<AccessTokenResponse>(`${this.apiUrl}/auth/login/token`, options)
      .pipe(
        switchMap(res => {
          return from(this.tokenService.isValid(res.access_token))
            .pipe(
              tap(isValid => {
                if (!isValid) {
                  throw new Error('invalid access token');
                }
              }),
              map(_ => {
                const tokenClaims = this.tokenService.decodeJwtClaims(res.access_token);
                return this.authenticationAdapter.fromJwtClaims(tokenClaims, res.access_token);
              })
            );
        }),
        tap(() => this.userUpdated.emit(true)),
      );
  }
}
