import { Injectable } from '@angular/core';
import { Adapter } from '../../adapter';
import { User, UserDto } from '../models/user';

@Injectable({
  providedIn: null,
})
export class UserAdapter extends Adapter<UserDto, User> {
  public toDto(item: User): UserDto {
    return {
      id: item.userId,
      mail: item.mail,
      given_name: item.givenName,
      surname: item.surname,
      is_admin: item.admin,
      is_client: item.client,
    } as UserDto;
  }

  public fromDto(item: UserDto): User {
    return {
      userId: item.id,
      mail: item.mail,
      givenName: item.given_name,
      surname: item.surname,
      status: item.status,
      admin: item.is_admin,
      client: item.is_client,
    };
  }
}
