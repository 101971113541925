import { Component } from '@angular/core';
import { ClientApiService } from "../../shared/api/client/client-api.service";
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { FileUtil } from 'src/app/shared/utils/file-util';
import { Platform } from "../../shared/api/client/models/platform.enum";
import { Store } from 'src/app/shared/api/client/models/store.enum';
import { ClientAvailability } from 'src/app/shared/api/client/models/client';
import { DialogService } from 'src/app/modules/dialog';
import { ConfirmDialogConfig } from 'src/app/shared/components/confirm-dialog';
import { getPlatformName } from 'src/app/shared/api/client/models/platform';
import { getStoreName } from 'src/app/shared/api/client/models/store';

const images = 'assets/images/profiles';

interface Client {
  platform: Platform,
  store: Store,
  title: string,
  subtitle: string,
  icon: string,
}

@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.scss']
})
export class ViewClientComponent {

  public clients: Client[] = [{
    platform: Platform.MacOS_X86_64,
    store: Store.NotAvailable,
    title: 'macOS',
    subtitle: 'Intel',
    icon: `${images}/apple.png`
  },{
    platform: Platform.MacOS_Arm64,
    store: Store.NotAvailable,
    title: 'macOS',
    subtitle: 'Apple Silicon',
    icon: `${images}/apple.png`,
  },{
    platform: Platform.Win_X86_64,
    store: Store.NotAvailable,
    title: 'Windows',
    subtitle: '64-bit',
    icon: `${images}/windows.png`
  },{
    platform: Platform.Win_I686,
    store: Store.NotAvailable,
    title: 'Windows',
    subtitle: '32-bit',
    icon: `${images}/windows.png`,
  },{
    platform: Platform.Debian_X86_64,
    store: Store.NotAvailable,
    title: 'Linux',
    subtitle: 'x86-64 (Debian)',
    icon: `${images}/debian.png`
  },{
    platform: Platform.Debian_Arm64,
    store: Store.NotAvailable,
    title: 'Linux',
    subtitle: '64-bit ARM (Debian)',
    icon: `${images}/debian.png`
  },{
    platform: Platform.Rpm_X86_64,
    store: Store.NotAvailable,
    title: 'Linux',
    subtitle: 'x86-64 (RPM)',
    icon: `${images}/rpm.png`
  },{
    platform: Platform.Rpm_Arm64,
    store: Store.NotAvailable,
    title: 'Linux',
    subtitle: '64-bit ARM (RPM)',
    icon: `${images}/rpm.png`
  },{
    platform: Platform.Ios,
    store: Store.NotAvailable,
    title: 'iOS',
    subtitle: '',
    icon: `${images}/apple.png`
  },{
    platform: Platform.Android,
    store: Store.NotAvailable,
    title: 'Android',
    subtitle: '',
    icon: `${images}/android.png`
  }];

  public vpnProfileDownload = false;

  constructor(
    private readonly clientService: ClientApiService,
    private readonly dialog: DialogService,
    private readonly snackBar: SnackBarService,
  ) {
    this.clientService.getClientAvailability().subscribe(
      (availabilities: ClientAvailability[]) => {
        this.clients.forEach(client => {
          const availability = availabilities.find(it => it.platform === client.platform);
          if (availability) {
            client.store = availability.store_type;
          }
      });
    });
  }

  public downloadVpnProfile() {
    this.clientService.getVpnProfile()
      .subscribe(
        profile => {
          const fileName = 'client.ovpn';
          const blob = new Blob([profile], { type: 'text/plain' });
          FileUtil.download(blob, fileName);
        },
        error => {
          let message = "Unexpected error has occurred while download your VPN profile, please contact your administrator";
          if (error.status === 404) {
            message = "There is no OpenVPN configuration available, please contact your administrator";
          }
          this.snackBar.error(message);
        });
  }

  public downloadClient(platform: Platform) {
    this.clientService.getClientDownloadUrl(platform)
      .subscribe((url: string) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_self');
        link.setAttribute('href', url);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }, error => {
        this.snackBar.error('Failed to generate the URL to download your requested binary');
        console.error(error);
      });
  }

  public hasDownloadLink(client: Client): boolean {
    return [Store.Direct, Store.AppleAppStore, Store.GooglePlayStore, Store.MicrosoftAppStore, Store.SnapStore].includes(client.store);
  }

  public isComingSoon(client: Client): boolean {
    return client.store === Store.NotAvailable;
  }

  public isPrivateStore(client: Client): boolean {
    return [Store.AppleTestflight, Store.GooglePlayStoreEaryAccess].includes(client.store);
  }

  public async privateStoreInformation(client: Client) {
    if (!this.isPrivateStore(client)) {
      return;
    }

    await this.dialog.openConfirmDialog(new ConfirmDialogConfig({
      title: `${getPlatformName(client.store)} Client Availability`,
      content: [
        `Your client is only available through ${getStoreName(client.store)} programme, please contact your administrator.`,
      ],
      button1: 'Dismiss',
    }));
  }

  public onDownloadClicked(client: Client) {
    if (this.hasDownloadLink(client)) {
      this.downloadClient(client.platform);
    } else if (this.isPrivateStore(client)) {
      this.privateStoreInformation(client);
    }
  }

}
