import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { DeploymentConfigService } from '../../deployment-config.service';
import { UserAdapter } from './adapters/user.adapter';
import { USER_MEDIA_TYPE, User, UserDto } from './models/user';
import { HttpHeader } from '../http-header';

export const MERGE_PATCH_MEDIA_TYPE = 'application/merge-patch+json';

@Injectable({
  providedIn: null,
})
export class UserApiService {
  private baseUrl!: string;
  private readOnly = false;

  constructor(
    private readonly http: HttpClient,
    private readonly userAdapter: UserAdapter,
    private readonly config: DeploymentConfigService,
  ) {
    this.config.configLoaded.subscribe(() => {
      this.baseUrl = `${this.config.get('api')}/users`;
      this.readOnly = config.get('read-only');
    });
  }

  public getVpnUser(userId: string): Observable<User> {
    const headers = {
      [HttpHeader.Accept]: USER_MEDIA_TYPE,
    };
    return this.http.get<UserDto>(`${this.baseUrl}/${userId}`, { headers })
      .pipe(map(it => this.userAdapter.fromDto(it)));
  }

  public getVpnUsers(): Observable<User[]> {
    const headers = {
      [HttpHeader.Accept]: USER_MEDIA_TYPE,
    };
    return this.http.get<UserDto[]>(`${this.baseUrl}?group=clients`, { headers })
      .pipe(map(it => it.map(item => this.userAdapter.fromDto(item))));
  }

  public createVpnUser(user: User): Observable<void> {
    if (this.readOnly) {
      throw Error('Creating a user is not allowed while read-only mode');
    }
    const headers = {
      [HttpHeader.ContentType]: USER_MEDIA_TYPE,
    };
    return this.http.post<void>(`${this.baseUrl}`,
      this.userAdapter.toDto(user), { headers });
  }

  public patchVpnUser(user: User): Observable<void> {
    const headers = {
      [HttpHeader.ContentType]: MERGE_PATCH_MEDIA_TYPE,
    };
    return this.http.patch<void>(`${this.baseUrl}/${user.userId}`,
      this.userAdapter.toDto(user), { headers });
  }

  public revokeVpnUser(userId: string): Observable<void> {
    if (this.readOnly) {
      throw Error('Revoking a user is not allowed while read-only mode');
    }
    const headers = {
      [HttpHeader.ContentType]: USER_MEDIA_TYPE,
    };
    return this.http.post<void>(`${this.baseUrl}/${userId}/revoke`, {}, { headers });
  }

  public activateVpnUser(userId: string): Observable<void> {
    if (this.readOnly) {
      throw Error('Activating a user is not allowed while read-only mode');
    }
    const headers = {
      [HttpHeader.ContentType]: USER_MEDIA_TYPE,
    };
    return this.http.post<void>(`${this.baseUrl}/${userId}/activate`, {}, { headers });
  }

  public deleteVpnUser(userId: string): Observable<void> {
    if (this.readOnly) {
      throw Error('Deleting a user is not allowed while read-only mode');
    }
    return this.http.delete<void>(`${this.baseUrl}/${userId}`);
  }
}
