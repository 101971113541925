import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss']
})
export class RootPageComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {
    console.warn(`Root page is a placeholder and shouldn't have been hit. Check the root guard.`);
  }
}
