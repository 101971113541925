import { Store } from './store.enum';

export function getPlatformName(store: Store): string {
  switch (store) {
    case Store.AppleAppStore:
    case Store.AppleTestflight:
      return 'iOS';
    case Store.GooglePlayStore:
    case Store.GooglePlayStoreEaryAccess:
      return 'Android';
    case Store.MicrosoftAppStore:
      return 'Windows';
    case Store.SnapStore:
      return 'Linux';
    default:
      return String(store);
  }
}
