import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/modules/permission/service/permission.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(
    private readonly permission: PermissionService,
    private readonly router: Router,
  ) { }

  canActivate(): boolean {
    if (this.permission.canViewAdmin()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
