import { Injectable } from "@angular/core";
import { Role } from "./api/accounts/models/role.enum";

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly accessToken = 'accessToken';
  private readonly userRoles = 'roles';
  private readonly username = 'username';

  constructor() {
  }

  public isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  public setUser(username: string, accessToken: string, roles: Role[]): void {
    this.set(this.accessToken, accessToken);
    this.set(this.userRoles, JSON.stringify(roles));
    this.set(this.username, username);
  }

  public getAccessToken(): string | null {
    return this.get(this.accessToken);
  }

  public hasRole(role: Role): boolean {
    return this.getRoles()?.includes(role) ?? false;
  }

  public getRoles(): Role[] | null {
    const roles = this.get(this.userRoles);
    return roles ? JSON.parse(roles) : null;
  }

  public isCurrentUsername(username: string): boolean {
    return this.getUsername() === username;
  }

  public getUsername(): string | null {
    return this.get(this.username);
  }

  public clearUser(): void {
    localStorage.removeItem(this.accessToken);
    localStorage.removeItem(this.username);
    localStorage.removeItem(this.userRoles);
  }

  private set(key: string, value: string): void {
    localStorage.setItem(key.toString(), value);
  }

  private get(key: string): string | null {
    return localStorage.getItem(key);
  }
}
