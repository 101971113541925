export enum Platform {
  Win_X86_64 = "WIN_X86_64",
  Win_I686 = "WIN_I686",
  MacOS_X86_64 = "MAC_OS_X86_64",
  MacOS_Arm64 = "MAC_OS_ARM64",
  Debian_X86_64 = "DEBIAN_X86_64",
  Debian_Arm64 = "DEBIAN_ARM64",
  Rpm_X86_64 = "RPM_X86_64",
  Rpm_Arm64 = "RPM_ARM64",
  Ios = "IOS",
  Android = "ANDROID",
}
