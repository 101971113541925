export abstract class FileUtil {
  /**
   * Convert a file size into a human readable format.
   *
   * @param fileSize A file size in bytes
   * @param accuracy The number of decimal points of accuracy
   * @returns The human readable version of that file size
   */
  public static formatFileSize(fileSize: number, accuracy = 2): string {
    const kb = 1024;
    const mb = 1024 * kb;
    const gb = 1024 * mb;
    const tb = 1024 * gb;

    if (accuracy > 20) {
      console.warn(`File size accuracy cannot exceed 20; using 20 instead.`, `Arguments were:`, { size: fileSize, accuracy });
      accuracy = 20;
    }

    function present(size: number): string {
      if (Number.isInteger(size)) {
        return size.toString();
      } else {
        return size.toFixed(accuracy);
      }
    }

    if (fileSize < 1024) {
      return `${fileSize} B`;
    } else if (fileSize < mb) {
      return `${present(fileSize / kb)} KB`;
    } else if (fileSize < gb) {
      return `${present(fileSize / mb)} MB`;
    } else if (fileSize < tb) {
      return `${present(fileSize / gb)} GB`;
    } else {
      return `${present(fileSize / tb)} TB`;
    }
  }

  public static download(file: Blob | File, filename: string): void {
    const downloadUrl = window.URL.createObjectURL(file);
    const anchor = document.createElement('a');
    anchor.href = downloadUrl;
    anchor.download = filename;
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
    anchor.remove();
  }
}
