export enum Store {
  NotAvailable = "NOT_AVAILABLE",
  Direct = "DIRECT",
  AppleAppStore = "APPLE_APP_STORE",
  AppleTestflight = "APPLE_TESTFLIGHT",
  GooglePlayStore = "GOOGLE_PLAY_STORE",
  GooglePlayStoreEaryAccess = "GOOGLE_PLAY_STORE_EARLY_ACCESS",
  MicrosoftAppStore = "MICROSOFT_APP_STORE",
  SnapStore = "SNAP_STORE",
}
