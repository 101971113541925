import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewClientComponent } from './view-client/view-client.component';
import { NgMatModule } from "../shared/modules/ngmat.module";
import { ClientsRoutingModule } from "./clients.routing.module";
import { GridColsDirective } from "../shared/directives/grid-cols.directive";
import { ClientApiModule } from '../shared/api/client/client-api.module';
import { PermissionModule } from '../modules/permission/permission.module';

@NgModule({
  declarations: [
    ViewClientComponent,
    GridColsDirective,
  ],
  imports: [
    CommonModule,
    ClientsRoutingModule,
    NgMatModule,
    ClientApiModule,
    PermissionModule,
  ],
  exports: [
    GridColsDirective
  ],
})
export class ClientsModule { }
