import { Role } from './role.enum';
import { UserStatus } from './user-status.enum';

export class AuthDetail {
  constructor(
    public userId: string,
    public username: string,
    public roles: Role[],
    public userStatus: UserStatus
  ) { }
}

export enum AuthType {
  USER_PASS = 'USER_PASS',
  OIDC = 'OIDC',
}

export class AuthTypeResponse {
  constructor(
    public name: string,
    public auth_type: AuthType,
    public version: string,
    public requires: string
  ) {}
}

export class AuthProvidersResponse {
  constructor(
    public authorization_endpoint: string,
    public client_id: string,
    public id: string,
    public name: string,
    public scope: string[],
    public use_pkce: boolean
  ) {}
}


export class Authentication {
  public accessToken: string;
  public detail: AuthDetail;

  constructor(accessToken: string, detail: AuthDetail) {
    this.accessToken = accessToken;
    this.detail = detail;
  }
}
