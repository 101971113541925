@if (busy) {

<mat-spinner diameter="50"></mat-spinner>

} @else if(isOIDC) {

<section>
  <img alt="Tunnr" src="/assets/images/logo/tunnr-text-logo.svg" class="logo">
  <button
  mat-button
  color="primary"
  class="btn-nomidio"
  (click)="authApi.authenticateWithOpenId()">
    <img alt="Nomidio" src="assets/images/nomidio-icon.svg"/>
    Sign in with Nomidio ID
  </button>
</section>

} @else {

<form class="container" [formGroup]="loginForm" novalidate (ngSubmit)="login()">
  <h2>Log in</h2>

  <div class="field">
    <div>Username</div>
    <mat-form-field appearance="outline" class="force-center">
      <input
        type="text"
        matInput
        formControlName="username"
        autocomplete="off"
      />
      <mat-error
        name="login-username-error"
        *ngIf="loginForm.controls.username.hasError('required')"
      >
        Username is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <div>Password</div>
    <mat-form-field appearance="outline" class="force-center">
      <input
        type="password"
        matInput
        formControlName="password"
        autocomplete="off"
      />
      <mat-error
        name="login-password-error"
        *ngIf="loginForm.controls.password.hasError('required')"
      >
        Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="actions">
    <button
      type="submit"
      [disabled]="loginForm.invalid || busy"
      class="btn btn-primary"
    >
      Log in
    </button>
  </div>
</form>

}
