import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StoreService } from "../../../store.service";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private store: StoreService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bearerToken = this.store.getAccessToken();

    if (bearerToken && this.isBearerTokenRequired(request)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${bearerToken}`
        }
      });
    }

    return next.handle(request);
  }

  private isBearerTokenRequired(request: HttpRequest<any>): boolean {
    return !request.url.endsWith('/auth/login/token')
      && !request.url.endsWith('/.well-known/jwks');
  }
}
