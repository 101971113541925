import { Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmDialogConfig extends MatDialogConfig<ConfirmDialogData> {
  public readonly autoFocus = true;
  public readonly disableClose = false;
  public readonly width = '550px';
  public readonly maxWidth = '100%';
  public readonly panelClass = 'app-dialog';

  constructor(data: ConfirmDialogData) {
    super();
    this.data = data;
  }
}

export interface ConfirmDialogData {
  title: string;
  content: string | string[];
  button1: string;
  button2?: string;
  button2Theme?: string;
}

export enum ConfirmDialogButton {
  Button1 = 'button1',
  Button2 = 'button2',
}

export type ConfirmDialogReturnType = ConfirmDialogButton;

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public readonly ConfirmDialogButton = ConfirmDialogButton;
  public title: string;
  public content: string[] = [];
  public button1: string;
  public button2: string | undefined = undefined;
  public button2Theme = 'btn-primary';

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmDialogComponent, ConfirmDialogReturnType>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {
    this.title = data.title;
    this.content = (typeof data.content === 'string') ? [data.content] : data.content;
    this.button1 = data.button1;
    if (data.button2) {
      this.button2 = data.button2;
    }
    if (data.button2Theme) {
      this.button2Theme = data.button2Theme;
    }
  }

  public show(field: any): boolean {
    return !!field && field !== '' && field?.length !== 0;
  }

  public close(button?: ConfirmDialogButton) {
    this.dialogRef.close(button);
  }
}
