import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JWK, JWS } from 'node-jose';
import { map } from 'rxjs/operators';
import { DeploymentConfigService } from './deployment-config.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private apiUrl!: string;

  constructor(
    private readonly http: HttpClient,
    private readonly config: DeploymentConfigService,
  ) {
    this.config.configLoaded.subscribe(() => {
      this.apiUrl = this.config.get('api');
    });
  }

  public decodeJwtClaims(token: string): Map<string, any> {
    const claims = JSON.parse(atob(token.split('.')[1]));
    const claimMap = new Map();
    for (const key of Object.keys(claims)) {
      claimMap.set(key, claims[key]);
    }
    return claimMap;
  }

  public decodeJwtHeader(token: string): Map<string, any> {
    const header = JSON.parse(atob(token.split('.')[0]));
    const headerMap = new Map();
    for (const key of Object.keys(header)) {
      headerMap.set(key, header[key]);
    }
    return headerMap;
  }

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async isValid(idToken: string, clientId?: string, nonce?: string): Promise<boolean> {
    try {
      const keyStore = await this.getKeyStore(idToken);
      const verifier = JWS.createVerify(keyStore);
      await verifier.verify(idToken);
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async getKeyStore(idToken: string): Promise<JWK.KeyStore> {
    const jwksUri = await this.getJwksUri();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/jwk-set+json; charset=UTF-8',
      })
    };

    return this.http.get(jwksUri, httpOptions)
      .pipe(map(jwkSet => JWK.asKeyStore(jwkSet))).toPromise();
  }

  private async getJwksUri(): Promise<string> {
    return `${this.apiUrl}/.well-known/jwks.json`;
  }

  private isValidAudience(claims: Map<any, any>, expectedAudience: string) {
    const audClaim = claims.get('aud');
    if (!audClaim) {
      return false;
    } else if (typeof audClaim === 'string' || audClaim instanceof String) {
      return audClaim === expectedAudience;
    } else if (audClaim instanceof Array) {
      return audClaim.some(aud => aud === expectedAudience);
    }
    return false;
  }
}
