<div class="content">
  <mat-grid-list *ngIf="vpnProfileDownload" cols="1" rowHeight="350px">
    <mat-grid-tile>
      <mat-card class="pointer" (click)="downloadVpnProfile()">
        <mat-card-header>
          <mat-card-title>VPN Profile</mat-card-title>
        </mat-card-header>
        <mat-card-content class="content">
          <div class="profile-content">In order to start your VPN client, you will need to download this VPN profile and load it into your client.</div>
          <img src="assets/images/profiles/vpn.png" alt="VPN logo" width="64px" class="icon">
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list [appGridCols]="{xs: 1, sm: 2, md: 5, lg: 5, xl: 5}" rowHeight="350px">
    <h2 class="center mat-h2">VPN Clients</h2>
    <mat-grid-tile *ngFor="let it of clients">
      <mat-card [ngClass]="{ 'pointer' : hasDownloadLink(it) || isPrivateStore(it) }" (click)="onDownloadClicked(it)">
        <mat-card-header>
          <mat-card-title class="product-title">{{ it.title }}</mat-card-title>
          <mat-card-subtitle class="product-subtitle">{{ it.subtitle }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <img [src]="it.icon" alt="Client logo" width="64px" class="icon">
          <div class="client-content" [ngClass]="{ 'italic': isComingSoon(it) }">{{ isComingSoon(it) ? 'Coming Soon' : 'Download' }}</div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
