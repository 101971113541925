import { Injectable } from '@angular/core';
import { AuthDetail, Authentication } from '../models/authentication';
import { Role } from '../models/role.enum';
import { UserStatus } from '../models/user-status.enum';

@Injectable({
  providedIn: null,
})
export class AuthenticationAdapter {
  fromJwtClaims(claims: Map<string, any>, accessToken: string) {
    const authDetail = new AuthDetail(
      claims.get('uid') as string,
      claims.get('sub') as string,
      claims.get('roles') as Role[],
      claims.get('status') as UserStatus);
    return new Authentication(accessToken, authDetail);
  }
}
