<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav class="app-sidenav" mode="side" opened *ngIf="isSideBarVisible()">
    <img alt="Tunnr" src="/assets/images/logo/tunnr-text-logo.svg" class="logo">
    <div class="divider"></div>
    <nav>
      <ul>
        @for (item of menuItems; track $index) {
          <li *ngIf="item.canShow()">
            <button mat-flat-button type="button" [class.selected]="isSelected(item)"
              [attr.id]="item.id" (click)="item.action()">
              <div class="button-content">
                <mat-icon>{{item.icon}}</mat-icon>
                <span class="nav-text">{{item.text}}</span>
              </div>
            </button>
          </li>
        }
      </ul>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
