export abstract class ReadOnlyAdapter<D, M> {
  public fromDtoList(dtos: D[]): M[] {
    return dtos.map(dto => this.fromDto(dto));
  }

  // TODO: Convert to static abstract method when implemented in typescript https://github.com/microsoft/TypeScript/issues/34516
  public abstract fromDto(dto: D): M;
}

export abstract class Adapter<D, M> extends ReadOnlyAdapter<D, M> {
  public toDtoList(models: M[]): D[] {
    return models.map(model => this.toDto(model));
  }

  // TODO: Convert to static abstract method when implemented in typescript https://github.com/microsoft/TypeScript/issues/34516
  public abstract toDto(model: M): D;
}
