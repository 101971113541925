import { NgModule } from '@angular/core';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule, MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@angular/material/paginator';
import { RequireSome } from '../utils/require-some.type';

const modules = [
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
];

/**
 * Our default configuration for the paginator.
 * Some fields are marked as required so that they can be used in other locations sourcing this configuration.
 */
export const matPaginatorDefaultOptions: RequireSome<MatPaginatorDefaultOptions, 'pageSize'> = {
  pageSizeOptions: [ 10, 25, 50, 100 ],
  pageSize: 25,
  showFirstLastButtons: true,
  hidePageSize: false,
};

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: matPaginatorDefaultOptions }
  ]
})
export class NgMatModule { }
