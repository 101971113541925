import { Injectable } from '@angular/core';
import { AuthProvidersResponse } from '../../api/accounts/models/authentication';
import { Utils as jscrypto } from '@post-quantum/jscrypto';

@Injectable({
  providedIn: 'root'
})
export class OpenIDService {
  STATE_STORAGE_KEY = 'nomidio-login-state';
  CODE_VERIFIER_KEY = 'nomidio-code-verifier';

  public async generateOpenIdUrl(provider: AuthProvidersResponse) {
    const oauthUrl = new URL(provider.authorization_endpoint);
    const codeVerifier = this.generateCodeVerifier();
    this.saveCodeVerifier(codeVerifier);
    this.saveState(this.generateState());

    oauthUrl.searchParams.append('response_type', 'code');
    oauthUrl.searchParams.append('scope', 'openid email');
    oauthUrl.searchParams.append('client_id', provider.client_id);
    oauthUrl.searchParams.append('redirect_uri', `${window.origin}/login`);
    oauthUrl.searchParams.append('state', this.getState()!);
    oauthUrl.searchParams.append('code_challenge_method', 'S256');
    oauthUrl.searchParams.append('code_challenge', await this.generateCodeChallenge(codeVerifier));
    return oauthUrl.toString();
  }

  public getCodeVerifier(): string | null {
    return sessionStorage.getItem(this.CODE_VERIFIER_KEY);
  }

  public getState(): string | null {
    return sessionStorage.getItem(this.STATE_STORAGE_KEY);
  }

  private generateState(): string {
    const randomArray = jscrypto.getCrypto().getRandomValues(new Uint8Array(16));
    const base64String = jscrypto.uint8ArrayToBase64(randomArray);
    return this.base64toBase64Url(base64String);
  }

  private generateCodeVerifier(): string {
    const scope = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const randomArray = jscrypto.getCrypto().getRandomValues(new Uint8Array(64));
    const codeVerifierBytes = randomArray.map(v => scope.charCodeAt(v % scope.length));
    return new TextDecoder().decode(codeVerifierBytes);
  }

  private async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const codeVerifierBytes = new TextEncoder().encode(codeVerifier);
    const rawCodeChallenge = await window.crypto.subtle.digest('SHA-256', codeVerifierBytes);
    const base64String = jscrypto.uint8ArrayToBase64(new Uint8Array(rawCodeChallenge));
    return this.base64toBase64Url(base64String);
  }

  private saveState(state: string) {
    sessionStorage.setItem(this.STATE_STORAGE_KEY, state);
  }

  private saveCodeVerifier(codeVerifier: string) {
    sessionStorage.setItem(this.CODE_VERIFIER_KEY, codeVerifier);
  }

  // as per RFC7636
  private base64toBase64Url(base64Str: string): string {
    return base64Str.split('=')[0]
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }
}
