import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_VERSION } from '../../environments/version';
import { ReplaySubject } from 'rxjs';

export interface DeploymentConfiguration {
  api: string;
  'google-maps-api-key': string;
  'read-only': boolean;
}

interface Config extends DeploymentConfiguration {}

class Config implements DeploymentConfiguration {

  'read-only': boolean = false;

  private _booleanFields: string[] = ['read-only'];

  constructor(data: any) {
    for (const [k, v] of Object.entries(data)) {
      if(this._booleanFields.includes(k)) {
        (<any>this)[k] = String(v).toLowerCase() === 'true';
      } else {
        (<any>this)[k] = v;
      }
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class DeploymentConfigService {
  public readonly configLoaded = new ReplaySubject<void>();
  private _config: DeploymentConfiguration | null = null;

  constructor(private readonly http: HttpClient) { }

  async load(): Promise<void>  {
    const data = await this.http.get<any>('/assets/config/deployment.json').toPromise();
    this._config = new Config(data);
    this.configLoaded.next();
  }

  get loaded(): boolean { return !!this._config; }

  get version(): string { return APP_VERSION; }

  public get<K extends keyof DeploymentConfiguration>(key: K): DeploymentConfiguration[K] {
    if (!this._config) {
      throw Error('Config requested before it was loaded');
    }
    return this._config[key];
  }
}
