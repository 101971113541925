import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { ConfirmDialogConfig, ConfirmDialogReturnType, ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private readonly matDialog: MatDialog,
    private readonly router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.matDialog.closeAll();
      }
    });
  }

  public open<R = any, T = any, D = any>(component: ComponentType<T>, config?: MatDialogConfig<D>) {
    return this.matDialog.open<T, D, R>(component, config);
  }

  /**
   * Open a confirmation dialog & return its output.
   *
   * @param config The dialog config
   * @returns The output of the dialog
   */

  public openConfirmDialog(config: ConfirmDialogConfig): Promise<ConfirmDialogReturnType | undefined> {
    return new Promise(resolve => {
      this.open<ConfirmDialogReturnType>(ConfirmDialogComponent, config).afterClosed().subscribe(button => {
        resolve(button);
      });
    });
  }

  public closeAll(): void {
    this.matDialog.closeAll();
  }
}
