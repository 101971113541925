import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/shared/store.service';

@Injectable({ providedIn: 'root' })
export class AlreadyLoggedInGuard  {

  constructor(
    private readonly router: Router,
    private readonly store: StoreService,
  ) {
  }

  public canActivate(): boolean {
    if (this.store.isLoggedIn()) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
