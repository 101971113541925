import { Component } from '@angular/core';
import {Event, NavigationEnd, Router} from "@angular/router";
import { StoreService } from "../shared/store.service";
import { PermissionService } from '../modules/permission/service/permission.service';

interface SidebarMenuItem {
  id: string;
  route?: string;
  canShow: () => boolean | undefined | null;
  action: () => any;
  icon: string;
  text: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public readonly menuItems: SidebarMenuItem[] = [
    {
      id: 'dashboard',
      route: '/dashboard',
      canShow: () => this.permission.canViewAdmin(),
      action: () => this.goTo('/dashboard'),
      icon: 'dashboard',
      text: 'Dashboard',
    },
    {
      id: 'users',
      route: '/users',
      canShow: () => this.permission.canViewAdmin(),
      action: () => this.goTo('/users'),
      icon: 'supervisor_account',
      text: 'Users',
    },
    {
      id: 'requests',
      route: '/certificates/pending',
      canShow: () => this.permission.canViewAdmin(),
      action: () => this.goTo('/certificates/pending'),
      icon: 'feed',
      text: 'Pending Requests',
    },
    {
      id: 'clients',
      route: '/clients',
      canShow: () => this.permission.canViewClients(),
      action: () => this.goTo('/clients'),
      icon: 'public',
      text: 'My Clients',
    },
    {
      id: 'logout',
      canShow: () => true,
      action: () => this.logout(),
      icon: 'exit_to_app',
      text: 'Logout',
    }
  ];

  public currentRoute: string;

  constructor(
    private readonly permission: PermissionService,
    private readonly storeService: StoreService,
    private readonly router: Router,
  ) {
    this.currentRoute = '';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }

  public isSideBarVisible(): boolean {
    const url = this.router.url;

    if (url === '/') {
      return false;
    }

    const hideIn = ['/login', '/register', '/reset'];
    for (const start of hideIn) {
      if (url.startsWith(start)) {
        return false;
      }
    }

    return this.storeService.isLoggedIn();
  }

  public isSelected(item: SidebarMenuItem): boolean {
    if (item.route && this.currentRoute) {
      return this.currentRoute.startsWith(item.route);
    }
    return false;
  }

  public goTo(route: string) {
    this.router.navigate([route]);
  }

  public logout() {
    this.storeService.clearUser();
    this.router.navigate(['/login']);
  }
}
