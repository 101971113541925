import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { DeploymentConfigService } from '../../deployment-config.service';
import { UserAdapter } from './adapters/user.adapter';
import { USER_MEDIA_TYPE, User, UserDto } from './models/user';
import { AccountResetRequest, ACCOUNT_RESET_MEDIA_TYPE } from './models/account-reset.request';
import { HttpHeader } from '../http-header';

@Injectable({
  providedIn: null,
})
export class AccountApiService {
  private baseUrl!: string;

  constructor(
    private readonly http: HttpClient,
    private readonly userAdapter: UserAdapter,
    private readonly config: DeploymentConfigService,
  ) {
    this.config.configLoaded.subscribe(() => {
      this.baseUrl = `${this.config.get('api')}/users`;
    });
  }

  public registerAccount(userId: string, token: string, password: string): Observable<void> {
    const headers = {
      [HttpHeader.ContentType]: ACCOUNT_RESET_MEDIA_TYPE,
    };

    const body: AccountResetRequest = {
      password
    };

    return this.http.post<void>(`${this.baseUrl}/${userId}/register?token=${token}`, body, { headers });
  }

  public resetPassword(userId: string, token: string, password: string): Observable<void> {
    const headers = {
      [HttpHeader.ContentType]: ACCOUNT_RESET_MEDIA_TYPE,
    };

    const body: AccountResetRequest = {
      password
    };

    return this.http.post<void>(`${this.baseUrl}/${userId}/reset?token=${token}`, body, { headers });
  }

  public getAccount(userId: string): Observable<User> {
    const headers = {
      [HttpHeader.Accept]: USER_MEDIA_TYPE,
    };
    return this.http.get<UserDto>(`${this.baseUrl}/${userId}`, { headers })
      .pipe(map(it => this.userAdapter.fromDto(it)));
  }

  public getOwnAccount(): Observable<User> {
    const headers = {
      [HttpHeader.Accept]: USER_MEDIA_TYPE,
    };
    return this.http.get<UserDto>(`${this.baseUrl}/me`, { headers })
      .pipe(map(it => this.userAdapter.fromDto(it)));
  }
}
