import { Injectable } from '@angular/core';
import { Role } from 'src/app/shared/api/accounts/models/role.enum';
import { DeploymentConfigService } from 'src/app/shared/deployment-config.service';
import { StoreService } from 'src/app/shared/store.service';

@Injectable({
  providedIn: null,
})
export class PermissionService {
  constructor(
    private readonly store: StoreService,
    private readonly config: DeploymentConfigService,
  ) { }

  public isLoggedIn(): boolean {
    return this.store.isLoggedIn();
  }

  public canViewAdmin(): boolean {
    return this.store.hasRole(Role.Admin);
  }

  public canViewClients(): boolean {
    return this.store.hasRole(Role.Client);
  }

  public canModify(): boolean {
    return !this.config.get('read-only');
  }
}
