import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationApiService } from '../../shared/api/accounts/authentication-api.service';
import { StoreService } from '../../shared/store.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { Authentication } from 'src/app/shared/api/accounts/models/authentication';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public readonly loginForm = new FormGroup({
    username: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  public busy = true;
  public isOIDC = false;

  constructor(
    private readonly router: Router,
    private readonly storeService: StoreService,
    private readonly snackBar: SnackBarService,
    protected readonly authApi: AuthenticationApiService,
  ) {
    this.initForm();
  }

  public async login() {
    this.busy = true;
    const username = this.loginForm.controls.username.value.trim();
    const password = this.loginForm.controls.password.value;
    this.snackBar.dismiss();

    try {
      const authentication: Authentication = await this.authApi
        .loginWithCredentials(username, password)
        .toPromise();
      this.initNavigation(authentication);
    } catch (error) {
      this.storeService.clearUser();
      this.snackBar.error(
        'Login failed, your username or password is incorrect'
      );
      console.error('Login failed', error);
      this.busy = false;
    }
  }

  private async handleOpenIdResponse() {
    if (this.authApi.hasErrorCode() || !this.authApi.verifyState()) {
      this.busy = false;
      this.snackBar.error(this.authApi.getErrorCode() == 'access_denied' ? 'Access Denied' : 'Open ID Authenication failed');
    } else {
      try {
        const authentication: Authentication = await this.authApi
          .loginWithOidc()
          .toPromise();
        this.initNavigation(authentication);
      } catch (error) {
        this.storeService.clearUser();
        this.snackBar.error('Login failed, please contact your administrator');
        console.error('Login failed', error);
        this.busy = false;
      }
    }
  }

  private initNavigation(authentication: Authentication) {
    this.storeService.setUser(
      authentication.detail.username,
      authentication.accessToken,
      authentication.detail.roles
    );
    this.router.navigate(['/']);
  }


  private async initForm() {
    this.isOIDC = await this.authApi.isOIDC();
    if (this.authApi.hasAuthCode() || this.authApi.hasErrorCode()) {
      this.handleOpenIdResponse();
    } else {
      this.busy = false;
    }
  }
}
