import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from "./auth.routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { NgMatModule } from "../shared/modules/ngmat.module";
import { LoginComponent } from './login/login.component';
import { AccountsApiModule } from '../shared/api/accounts/accounts-api.module';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NgMatModule,
  ],
  providers: [
    AccountsApiModule,
  ]
})
export class AuthModule { }
