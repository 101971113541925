import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlreadyLoggedInGuard } from "./auth";
import { environment } from "../environments/environment";
import { AdminGuard, RootPageComponent } from './root';
import { ClientsGuard } from './clients';
import { RootGuard } from './root/guards/root/root.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [AlreadyLoggedInGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'dashboard',
    canActivate: [AdminGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'users',
    canActivate: [AdminGuard],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'certificates',
    canActivate: [AdminGuard],
    loadChildren: () => import('./certificates/certificates.module').then(m => m.CertificatesModule)
  },
  {
    path: 'clients',
    canActivate: [ClientsGuard],
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule)
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [
      RootGuard,
    ],
    component: RootPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: environment.enableRouterTracing,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
