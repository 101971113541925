<h2 mat-dialog-title *ngIf="show(title)">
  {{title}}
</h2>

<div mat-dialog-content class="content">
  <p *ngFor="let paragraph of content" [innerHtml]="paragraph"></p>
</div>

<div mat-dialog-actions>
  <div class="actions">
    <button *ngIf="show(button1)" (click)="close(ConfirmDialogButton.Button1)" class="btn btn-primary btn-outline">{{ button1 }}</button>
    <button *ngIf="show(button2)" (click)="close(ConfirmDialogButton.Button2)" class="btn {{button2Theme}}">{{ button2 }}</button>
  </div>
</div>
