import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { DeploymentConfigService } from '../../deployment-config.service';
import { Platform } from "./models/platform.enum";
import { map } from "rxjs/operators";
import { ClientAvailability, ClientDto } from "./models/client";
import { HttpHeader } from '../http-header';

@Injectable({
  providedIn: null,
})
export class ClientApiService {
  private baseUrl!: string;

  constructor(
    private readonly http: HttpClient,
    private readonly config: DeploymentConfigService,
  ) {
    this.config.configLoaded.subscribe(() => {
      this.baseUrl = `${this.config.get('api')}/vpn`;
    });
  }

  public getVpnProfile(): Observable<any> {
    const headers = new HttpHeaders({
      [HttpHeader.Accept]: 'text/plain'
    });
    const url = new URL(`${this.baseUrl}/config`);
    url.searchParams.append('type', 'OPEN_VPN');

    return this.http.get(url.toString(), {
      responseType: 'arraybuffer',
      headers,
    });
  }

  public getClientAvailability(): Observable<ClientAvailability[]> {
    const headers = new HttpHeaders({
      [HttpHeader.Accept]: 'application/vnd.io.tunnr.api.client.availability.v1+json'
    });

    return this.http.get<ClientAvailability[]>(`${this.baseUrl}/client_availability`, { headers });
  }

  public getClientDownloadUrl(platform: Platform): Observable<string> {
    const headers = new HttpHeaders({
      [HttpHeader.Accept]: 'application/vnd.io.tunnr.api.client.url.v1+json'
    });

    return this.http.get<ClientDto>(`${this.baseUrl}/client_url?platform=${platform}`,
      { headers }).pipe(map((it: ClientDto) => it.url));
  }
}
