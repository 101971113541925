import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ViewClientComponent } from "./view-client/view-client.component";

@NgModule({
  imports: [RouterModule.forChild(
    [
      { path: '', component: ViewClientComponent },
    ],
  )],
  exports: [RouterModule],
})
export class ClientsRoutingModule {
}
