import { Store } from './store.enum';

export function getStoreName(store: Store): string {
  switch (store) {
  case Store.Direct:
    return 'Vendor Direct';
  case Store.AppleAppStore:
    return 'Apple App Store';
  case Store.AppleTestflight:
    return 'Apple Testflight';
  case Store.GooglePlayStore:
    return 'Google Play Store';
  case Store.GooglePlayStoreEaryAccess:
    return 'Google Play Store Early Access';
  case Store.MicrosoftAppStore:
    return 'Microsoft App Store';
  case Store.SnapStore:
    return 'Snapcraft Store';
  case Store.NotAvailable:
    return 'Not Available';
  default:
    return String(store);
  }
}
