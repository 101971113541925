import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

export type SnackBarConfig = {
  /** The snack bar message. */
  message: string;
  /**
   * An icon to display in the snack bar.
   * @example 'icon--warning'
   */
  icon?: string;
} & ({
  /** The snackbar action. This will appear on the button to close it. If you specify `keepOpen: true`, this is mandatory. */
  action?: string;
  /** The duration to keep the dialog open for, or `true` to keep it open until dismissed. */
  keepOpen?: number
} | {
  action: string;
  keepOpen: true;
});

@Injectable({
  providedIn: null,
})
export class SnackBarService {
  private readonly DEFAULT_DURATION = 4000;
  private readonly SNACK_BAR_CLASS = 'app-snack-bar';
  private readonly SNACK_BAR_ERROR_CLASS = 'app-snack-bar--error';

  constructor(
    private readonly matSnackBar: MatSnackBar,
  ) { }

  public open(config: SnackBarConfig): MatSnackBarRef<TextOnlySnackBar> {
    const duration = config.keepOpen === true ? undefined : (config.keepOpen ?? this.DEFAULT_DURATION);

    return this.matSnackBar.open(config.message, config.action, {
      panelClass: this.SNACK_BAR_CLASS,
      duration,
    });
  }

  public error(message: string): MatSnackBarRef<TextOnlySnackBar> {
    return this.matSnackBar.open(message, 'OK', {
      panelClass: [this.SNACK_BAR_CLASS, this.SNACK_BAR_ERROR_CLASS],
    });
  }

  public dismiss(): void {
    this.matSnackBar.dismiss();
  }
}
