import { UserStatus } from "./user-status.enum";

export const USER_MEDIA_TYPE = 'application/vnd.io.tunnr.api.user.v1+json';

export interface UserDto {
  id: string;
  mail: string;
  given_name: string;
  surname: string;
  status: UserStatus;
  is_admin: boolean;
  is_client: boolean;
}

export interface User {
  userId: string;
  mail: string;
  givenName: string;
  surname: string;
  status: UserStatus;
  admin: boolean;
  client: boolean;
}

export function createEmptyUser(): User {
  return {
    userId: '',
    mail: '',
    givenName: '',
    surname: '',
    status: UserStatus.Disabled,
    admin: false,
    client: false,
  };
}
